<template>
  <div class="home-container ">

    <div id="header-container">
      <div id="header-title">
        <div class="title-text1">TFT SET 11 </div>
        <div class="title-text2">Ranked Ladder Verseny</div>
        <!-- <img src="https://kutyatft.com/wp-content/uploads/2023/11/cropped-cropped-Logo-Transparent-2.png" alt="logo" /> -->
      </div>
      <div id="header-cuccok">
        <div id="header-cuccok-container" class="box">

          <div id="jobb">
            <div class="info-text">Verseny Infó</div>
            <div class="info-block">
              <ul>
                <li><span style="color:#d58657;">60.000</span> FT Díjalap</li>

                <li class="reg-text"><a href="https://discord.gg/aKeEcMAg8H" target="_blank">Ingyenes <span
                      style="color:#d58657 !important; ">Regisztráció</span>
                    Discordon <svg xmlns="http://www.w3.org/2000/svg" width="35" style="position: relative;top: 10px;"
                      shape-rendering="geometricPrecision" text-rendering="geometricPrecision"
                      image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
                      <path fill="#5865F2"
                        d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z" />
                      <g data-name="">
                        <g data-name="Discord Logos">
                          <path fill="#fff" fill-rule="nonzero"
                            d="M360.932 160.621a250.49 250.49 0 00-62.384-19.182 174.005 174.005 0 00-7.966 16.243 232.677 232.677 0 00-34.618-2.602c-11.569 0-23.196.879-34.623 2.58-2.334-5.509-5.044-10.972-7.986-16.223a252.55 252.55 0 00-62.397 19.222c-39.483 58.408-50.183 115.357-44.833 171.497a251.546 251.546 0 0076.502 38.398c6.169-8.328 11.695-17.193 16.386-26.418a161.718 161.718 0 01-25.813-12.318c2.165-1.569 4.281-3.186 6.325-4.756 23.912 11.23 50.039 17.088 76.473 17.088 26.436 0 52.563-5.858 76.475-17.09 2.069 1.689 4.186 3.306 6.325 4.756a162.642 162.642 0 01-25.859 12.352 183.919 183.919 0 0016.386 26.396 250.495 250.495 0 0076.553-38.391l-.006.006c6.278-65.103-10.724-121.529-44.94-171.558zM205.779 297.63c-14.908 0-27.226-13.53-27.226-30.174 0-16.645 11.889-30.294 27.179-30.294 15.289 0 27.511 13.649 27.249 30.294-.261 16.644-12.007 30.174-27.202 30.174zm100.439 0c-14.933 0-27.202-13.53-27.202-30.174 0-16.645 11.889-30.294 27.202-30.294 15.313 0 27.44 13.649 27.178 30.294-.261 16.644-11.984 30.174-27.178 30.174z"
                            data-name="Discord Logo - Large - White" />
                        </g>
                      </g>
                    </svg>
                  </a></li>
                <li>Bármi Kérdés, megtaláltok: <a href="https://www.twitch.tv/kutya06" target="_blank"><svg
                      data-name="Layer 1" id="Layer_1" width="38" viewBox="0 0 512 512"
                      style="position: relative;top: 10px;" xmlns="http://www.w3.org/2000/svg">
                      <title />
                      <rect fill="#8652f6" height="412.22" rx="55.43" width="412.22" x="49.89" y="49.89" />
                      <polygon
                        points="186.98 132.09 137.56 180.67 137.56 357.57 197.01 357.57 197.01 406.68 245.59 358.1 285.2 358.1 374.44 270.72 374.44 132.09 186.98 132.09" />
                      <polygon fill="#fff"
                        points="197.54 150.92 197.54 298.06 243.4 298.06 243.4 330.11 275.11 298.41 316.2 298.41 355.42 260.02 355.43 150.92 197.54 150.92" />
                      <rect height="58.79" width="19.71" x="251.23" y="185.42" />
                      <rect height="58.79" width="19.71" x="305.79" y="185.42" />
                    </svg>
                  </a>

                  <a href="https://discord.gg/pkwJdZ4HDY" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" shape-rendering="geometricPrecision"
                      style="position: relative;top: 10px;" text-rendering="geometricPrecision"
                      image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
                      <path fill="#5865F2"
                        d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z" />
                      <g data-name="">
                        <g data-name="Discord Logos">
                          <path fill="#fff" fill-rule="nonzero"
                            d="M360.932 160.621a250.49 250.49 0 00-62.384-19.182 174.005 174.005 0 00-7.966 16.243 232.677 232.677 0 00-34.618-2.602c-11.569 0-23.196.879-34.623 2.58-2.334-5.509-5.044-10.972-7.986-16.223a252.55 252.55 0 00-62.397 19.222c-39.483 58.408-50.183 115.357-44.833 171.497a251.546 251.546 0 0076.502 38.398c6.169-8.328 11.695-17.193 16.386-26.418a161.718 161.718 0 01-25.813-12.318c2.165-1.569 4.281-3.186 6.325-4.756 23.912 11.23 50.039 17.088 76.473 17.088 26.436 0 52.563-5.858 76.475-17.09 2.069 1.689 4.186 3.306 6.325 4.756a162.642 162.642 0 01-25.859 12.352 183.919 183.919 0 0016.386 26.396 250.495 250.495 0 0076.553-38.391l-.006.006c6.278-65.103-10.724-121.529-44.94-171.558zM205.779 297.63c-14.908 0-27.226-13.53-27.226-30.174 0-16.645 11.889-30.294 27.179-30.294 15.289 0 27.511 13.649 27.249 30.294-.261 16.644-12.007 30.174-27.202 30.174zm100.439 0c-14.933 0-27.202-13.53-27.202-30.174 0-16.645 11.889-30.294 27.202-30.294 15.313 0 27.44 13.649 27.178 30.294-.261 16.644-11.984 30.174-27.178 30.174z"
                            data-name="Discord Logo - Large - White" />
                        </g>
                      </g>
                    </svg>
                  </a>
                </li>
                <li>Szervezo: kutya06 <img width="74" style="position: relative;top: 25px;"
                    src="../assets/kutyalogo.png" />
                </li>

              </ul>
            </div>

          </div>


          <div id="bal">
            <div class="info-text">Tudnivalók</div>
            <div class="info-block">
              <ul>
                <li>Játssz ranked játékokat</li>
                <li>Elozd meg a többieket </li>
                <li>Start Március 25.-én</li>
                <li>2 naponta a legutolsók kiesnek</li>
                <li>Névváltást jelezni kell discordon </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div id="ladder-container">
        <Ladder />
      </div>
    </div>



  </div>
</template>

<script>
import Ladder from '@/components/Ladder.vue'
import { onMounted, getCurrentInstance } from "vue";
//import { onMounted } from "vue";
import axios from 'axios';
import { useStore } from '../store/store';

export default {
  name: 'Home',
  components: {
    Ladder,
  },
  computed: {

  },
  methods: {
    async GetData() {
      try {
        const response = await axios.get('https://www.kutya06.com:8080/HL', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    },
  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      const instance = getCurrentInstance();


      async function refreshData() {
        // do whatever you like here

        var data = await instance.proxy.GetData();

        ///adding test data  CHANGE BACK TO data.tier1.sort
        store.tier1 = data.tier1.sort(function (a, b) {

          var tierOrder = { "UNRANKED": 0, "BRONZE": 1, "SILVER": 2, "GOLD": 3, "PLATINUM": 4, "EMERALD": 5, "DIAMOND": 6, "MASTER": 7, "GRANDMASTER": 8, "CHALLENGER": 9 };
          var rankOrder = { "IV": 1, "III": 2, "II": 3, "I": 4 };
          var tierA = tierOrder[a.tier.toUpperCase()];
          var tierB = tierOrder[b.tier.toUpperCase()];

          if (tierA === tierB) {

            var rankA = rankOrder[a.rank.toUpperCase()];
            var rankB = rankOrder[b.rank.toUpperCase()];

            if (rankA === rankB) {
              return b.leaguePoints - a.leaguePoints;
            }

            return rankB - rankA;
          }

          if (a.kiesett && !b.kiesett) {
            return a - b;
          }

          return tierB - tierA;
        });


        store.tier2 = data.tier2.sort(function (a, b) {

          var tierOrder = { "UNRANKED": 0, "BRONZE": 1, "SILVER": 2, "GOLD": 3, "PLATINUM": 4, "EMERALD": 5, "DIAMOND": 6, "MASTER": 7, "GRANDMASTER": 8, "CHALLENGER": 9 };
          var rankOrder = { "IV": 1, "III": 2, "II": 3, "I": 4 };
          var tierA = tierOrder[a.tier.toUpperCase()];
          var tierB = tierOrder[b.tier.toUpperCase()];

          if (tierA === tierB) {

            var rankA = rankOrder[a.rank.toUpperCase()];
            var rankB = rankOrder[b.rank.toUpperCase()];

            if (rankA === rankB) {
              return b.leaguePoints - a.leaguePoints;
            }

            return rankB - rankA;
          }

          if (a.kiesett && !b.kiesett) {
            return a - b;
          }

          return tierB - tierA;
        });


      }

      refreshData();

      setInterval(refreshData, 10000);
    });

  },
};
</script>

<style scoped>
.home-container {
  background: url(https://cdn.oneesports.gg/cdn-data/2024/03/TeamfightTactics_InkbornFables_TFTSet11_Wallpaper.jpg);
  width: 100%;
  height: 154%;
  background-repeat: no-repeat;
  background-position: -120px -80px;
  /* background-attachment: fixed; */
  background-size: cover;
}

.reg-text a {
  text-decoration: none !important;
  color: rgb(235 219 191);
}

.reg-text a:hover {
  text-decoration: none !important;
  color: rgb(117, 170, 110) !important;
}


.grayscale {
  filter: grayscale(80%);
}

#header-cuccok-container>div.info-block>ul>li.reg-text>a>svg {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

#Layer_1 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}


#ladder-container {
  display: flex;
  width: 100%;
  height: max-content;
}

#header-container {
  width: 100%;
  height: 300px;
  display: block;
  padding-top: 50px;
  margin-bottom: 330px;
  color: rgb(8, 8, 8);
}

#header-title img {
  width: 150px;
  filter: grayscale(50%);
}

#header-title {
  width: 100%;
}

#header-cuccok {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  text-align: -webkit-center;
  height: 450px;
  text-align: center;
}

#header-cuccok ul {
  list-style: none;
  padding: 0px;
}

#header-cuccok-container {
  place-content: center;
  display: flex;
  min-height: 411px;
  position: relative;
}

.title-text1 {
  font-size: 65px;
  padding-bottom: 10px;
  filter: drop-shadow(8px 8px 10px rgb(247, 243, 243));
}

.title-text2 {
  font-size: 95px;
  filter: drop-shadow(8px 8px 10px rgb(247, 243, 243));
}

.info-text {
  font-size: 32px;
  margin-left: 70px;
  margin-right: 50px;
  margin-bottom: 5px;
  padding-top: 10px;
  color: rgb(235, 219, 191);
  text-decoration: underline;
}

.info-block {
  font-size: 20px;
  height: 300px;
  margin-left: 50px;
  margin-right: 50px;
  color: rgb(235, 219, 191);
  padding-top: 8px;
}

.info-block ul li {
  min-height: 55px;
  max-width: 430px;
}

.box {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  max-width: 1200px;
  padding: 5px;
  border: 2px solid #b78846;
}

.box:before,
.box:after {
  content: "•";
  position: absolute;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #b78846;
  border: 2px solid #b78846;
  line-height: 12px;
  top: 5px;
  text-align: center;
}

.box:before {
  left: 5px;
}

.box:after {
  right: 5px;
}

.box .box-inner {
  position: relative;
  border: 2px solid #b78846;
  padding: 40px;
}

.box .box-inner:before,
.box .box-inner:after {
  content: "•";
  position: absolute;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #b78846;
  border: 2px solid #b78846;
  line-height: 12px;
  bottom: -2px;
  text-align: center;
}

.box .box-inner:before {
  left: -2px;
}

.box .box-inner:after {
  right: -2px;
}




@media (max-width: 1450px) {

  .info-block {
    font-size: 16px !important;
    height: 200px !important;
    margin-left: 50px;
    margin-right: 50px;
    color: rgb(235, 219, 191);
  }

  .info-text {
    font-size: 20px !important;
  }

  .ladder-container {
    display: inline-table !important;
    text-align: left;
  }

}


@media (max-width: 890px) {


  #bal {
    display: none;
  }

  #header-cuccok-container[data-v-fae5bece] {
    place-content: center;
    display: flex;
    min-height: 285px;
    position: relative;
    width: max-content;
  }

  .info-block ul li[data-v-fae5bece] {
    min-height: 15px;
    max-width: 430px;
  }

  .box {
    max-width: 626px;
  }

  .title-text1 {
    font-size: 55px;
  }

  .title-text2 {
    font-size: 66px;
  }

  #ladder-container {
    overflow-x: scroll;
  }

  .info-block {
    font-size: 13px !important;
    height: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
    color: rgb(235, 219, 191);
  }

  .info-text {
    font-size: 16px !important;
  }

  .box {
    padding: 0px;
  }

  #t1-container {
    transform: scale(0.6);
    background: red;
  }

  #t2-container {
    transform: scale(0.6);
  }
}
</style>
