import { defineStore } from "pinia";

export const useStore = defineStore("store", {
    state: () => ({
        hangingProtocol: true,
        tier1: [],
        tier2: []
    }),
    getters: {

    },
    actions: {

    }
});