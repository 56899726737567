<template>
  <router-view />
</template>

<style>
@import url('https://fonts.cdnfonts.com/css/yumaro');

#main-container {
  background: rgb(64, 115, 173);
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100vh;
  background-color: navajowhite;
  font-family: 'Yumaro', sans-serif;
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


@media (max-width: 1065px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */

#app {
  font-size: 10px !important;
}

}
</style>
