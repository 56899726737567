<template>
  <div class="ladder-container">

    <div id="t2-container">
      <div id="title-t2">Casual</div>
      <table class="table table-t2 table-striped table-bordered">
        <tbody>
          <tr v-for="(player, index) in T2Players" :key="player.id" :class="player.kiesett ? 'out' : ''">
            <td class="pos">{{ index + 1 }}</td>

            <td
              v-if="!player.summonerName.includes('snorlax210k') && !player.summonerName.includes('TeemoYeetou') && !player.summonerName.includes('eleM') && !player.summonerName.includes('ADREAL')"
              class="td-name"><a :href="'https://tactics.tools/player/eune/' + player.summonerName" target="_blank"
                class="sumlink">{{
            player.summonerName }}</a></td>
            <td v-if="player.summonerName.includes('snorlax210k')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/snorlax210k'" target="_blank" class="sumlink">Kakamanó</a>
            </td>
            <td v-if="player.summonerName.includes('eleM')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/eleMMM'" target="_blank" class="sumlink">eleM</a>
            </td>
            <td v-if="player.summonerName.includes('ADREAL')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/ADŘEAL'" target="_blank" class="sumlink">ADREAL</a>
            </td>
            <td v-if="player.summonerName.includes('TeemoYeetou')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/TeemoYeetou'" target="_blank" class="sumlink">nem én
                vagyok</a>
            </td>





            <td class="td-tier" v-if="player.tier != 'UNRANKED'">
              <img v-if="rankImages[player.tier]" :src="rankImages[player.tier]" :alt="player.tier" />
            </td>

            <td class="td-tier" v-if="player.tier == 'UNRANKED'">
              <span>UNRANKED</span>
            </td>



            <td class="td-rank"><span
                v-if="player.tier != 'MASTER' && player.tier != 'GRANDMASTER' && player.tier != 'CHALLENGER'">{{
            player.rank }}</span></td>
            <td class="td-lp">{{ player.leaguePoints }} LP</td>
            <td class="td-twitch">
              <a v-if="player.twitch.length > 0" :href="'https://www.twitch.tv/' + player.twitch" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 157.675" width="64" height="64">
                  <path
                    d="M159.446 0l-3.543 11.516.08 53.15H169.2v7.087h7.087l7.087-7.087h12.4l23.03-23.03V0h-59.35zm53.15 38.1l-9.744 9.744h-15.945l-8.858 8.858v-8.858H164.76V6.2h47.834V38.1zM196.65 17.716h6.2v17.716h-6.2V17.716zm-15.945 0h6.2v17.716h-6.2V17.716zM256 100.097v41.633l-23.03 15.945h-14.173v-7.087l-10.63 7.087h-12.4v-7.087l-7.972 7.087h-23.03l-7.087-7.087-1.772 7.087-20.33-.053-8.017-7.033-.886 7.087-23.03-.053-.886-7.033-6.2 7.033-35.433.053-7.087-3.543v3.543H34.547l-21.26-13.287L0 131.1V72.637h27.46l13.287 13.287h60.235V72.637h49.606v13.287H163V93l7.087-7.087h15.06l13.287-13.287h28.346v13.287h15.06zM34.547 92.125h-12.4V78.837H6.2v49.606l9.744 10.63h18.602v-16.83h-12.4V108.07h12.4V92.125zm66.436 0H85.038v30.118h-6.2V92.125H63.78v30.118h-6.2V92.125h-16.83v46.948h49.606l10.63-10.63V92.125zm21.26 0h-15.06v46.948h15.06V92.125zm0-13.287h-15.06v7.086h15.06v-7.086zm34.547 13.287h-12.4V78.837h-15.945v49.606l9.744 10.63h18.602v-16.83h-12.4V108.07h12.4V92.125zm40.747 0h-24.803L163 101.87v26.574l10.63 10.63h23.917v-16.83H179.82V108.07h17.716V92.125zm52.263 9.744l-10.63-9.744h-18.602V78.837h-16.83v60.235h16.83V108.07h12.4v31.003h16.83V101.87z"
                    fill="#6441a4" fill-rule="evenodd" />
                </svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="t1-container">
      <div id="title-t1">PRO</div>
      <table class="table table-t1 table-striped table-bordered">
        <tbody>
          <tr v-for="(player, index) in T1Players" :key="player.id" :class="player.kiesett ? 'out' : ''">
            <td class="pos">{{ index + 1 }}</td>

            <td
              v-if="!player.summonerName.includes('Jhi') && !player.summonerName.includes('lsten') && !player.summonerName.includes('Vágott') && !player.summonerName.includes('Boxy') && !player.summonerName.includes('qrvák') && !player.summonerName.includes('Félszerzet')"
              class="td-name">
              <a :href="'https://tactics.tools/player/eune/' + player.summonerName" target="_blank" class="sumlink">{{
            player.summonerName }}</a>
            </td>
            <td v-if="player.summonerName.includes('Jhi')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/Jhiηger'" target="_blank" class="sumlink">{{
            player.summonerName }}</a></td>
            <td v-if="player.summonerName.includes('Vágott')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/' + player.summonerName" target="_blank"
                class="sumlink">Vágott</a></td>
            <td v-if="player.summonerName.includes('qrvák')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/hemxqq'" target="_blank" class="sumlink">HemxQQ</a></td>
            <td v-if="player.summonerName.includes('Félszerzet')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/patyesz'" target="_blank" class="sumlink">patyesz</a></td>
            <td v-if="player.summonerName.includes('Boxy')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/Boxy001'" target="_blank" class="sumlink">Boxy</a></td>
            <td v-if="player.summonerName.includes('lsten')" class="td-name"><a
                :href="'https://tactics.tools/player/eune/Lsten'" target="_blank" class="sumlink">Krinya</a></td>



            <td class="td-tier" v-if="player.tier != 'UNRANKED'">
              <img v-if="rankImages[player.tier]" :src="rankImages[player.tier]" :alt="player.tier" />
            </td>

            <td class="td-tier" v-if="player.tier == 'UNRANKED'">
              <span>UNRANKED</span>
            </td>


            <td class="td-rank"><span
                v-if="player.tier != 'MASTER' && player.tier != 'GRANDMASTER' && player.tier != 'CHALLENGER'">{{
            player.rank }}</span></td>
            <td class="td-lp">{{ player.leaguePoints }} LP</td>
            <td class="td-twitch">
              <a v-if="player.twitch.length > 0" :href="'https://www.twitch.tv/' + player.twitch" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 157.675" width="64" height="64">
                  <path
                    d="M159.446 0l-3.543 11.516.08 53.15H169.2v7.087h7.087l7.087-7.087h12.4l23.03-23.03V0h-59.35zm53.15 38.1l-9.744 9.744h-15.945l-8.858 8.858v-8.858H164.76V6.2h47.834V38.1zM196.65 17.716h6.2v17.716h-6.2V17.716zm-15.945 0h6.2v17.716h-6.2V17.716zM256 100.097v41.633l-23.03 15.945h-14.173v-7.087l-10.63 7.087h-12.4v-7.087l-7.972 7.087h-23.03l-7.087-7.087-1.772 7.087-20.33-.053-8.017-7.033-.886 7.087-23.03-.053-.886-7.033-6.2 7.033-35.433.053-7.087-3.543v3.543H34.547l-21.26-13.287L0 131.1V72.637h27.46l13.287 13.287h60.235V72.637h49.606v13.287H163V93l7.087-7.087h15.06l13.287-13.287h28.346v13.287h15.06zM34.547 92.125h-12.4V78.837H6.2v49.606l9.744 10.63h18.602v-16.83h-12.4V108.07h12.4V92.125zm66.436 0H85.038v30.118h-6.2V92.125H63.78v30.118h-6.2V92.125h-16.83v46.948h49.606l10.63-10.63V92.125zm21.26 0h-15.06v46.948h15.06V92.125zm0-13.287h-15.06v7.086h15.06v-7.086zm34.547 13.287h-12.4V78.837h-15.945v49.606l9.744 10.63h18.602v-16.83h-12.4V108.07h12.4V92.125zm40.747 0h-24.803L163 101.87v26.574l10.63 10.63h23.917v-16.83H179.82V108.07h17.716V92.125zm52.263 9.744l-10.63-9.744h-18.602V78.837h-16.83v60.235h16.83V108.07h12.4v31.003h16.83V101.87z"
                    fill="#6441a4" fill-rule="evenodd" />
                </svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>

import { useStore } from '../store/store';

export default {
  name: "Ladder",
  methods: {

  },
  data() {
    return {
      rankImages: {
        'UNRANKED': 'https://www.boxboxtft.com/ranks/diamond.png',
        'IRON': 'https://www.boxboxtft.com/ranks/iron.png',
        'BRONZE': 'https://www.boxboxtft.com/ranks/bronze.png',
        'SILVER': 'https://www.boxboxtft.com/ranks/silver.png',
        'GOLD': 'https://www.boxboxtft.com/ranks/gold.png',
        'PLATINUM': 'https://www.boxboxtft.com/ranks/platinum.png',
        'EMERALD': 'https://www.boxboxtft.com/ranks/emerald.png',
        'DIAMOND': 'https://www.boxboxtft.com/ranks/diamond.png',
        'MASTER': 'https://www.boxboxtft.com/ranks/master.png',
        'GRANDMASTER': 'https://www.boxboxtft.com/ranks/grandmaster.png',
        'CHALLENGER': 'https://www.boxboxtft.com/ranks/challenger.png',
      },
    };
  },
  computed: {
    T1Players() {
      const store = useStore();
      return store.tier1;
    },
    T2Players() {
      const store = useStore();
      return store.tier2;
    }
  },
  setup() {

  },
};
</script>

<style scoped>
.ladder-container {
  width: 100%;
  height: 100%;
  display: inline-flex;
  text-align: -webkit-center;
  background: #eed9ab;
  overflow: hidden;
  /* color:white; */
  min-height: 900px;
}

#t1-container {
  width: 50%;
  text-align: -webkit-center;
}

#t2-container {
  width: 50%;
  text-align: -webkit-center;
}

.table tr {
  font-size: 21px;
  border: 1px rgb(80, 80, 80) solid;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  width: max-content;
  background: #c9aa678c;
}

tbody {
  text-align: -webkit-center;
}

td.pos {
  background: rgb(193 176 127);
  min-width: 70px;
  height: 25px;
  text-align: center;
  font-size: 35px;
  border-right: 1px solid black;
}

#t1-container>table>tbody>tr:nth-child(1)>td.pos {
  background: rgb(215 198 74);
}

#t1-container>table>tbody>tr:nth-child(2)>td.pos {
  background: rgb(160, 158, 158);
}

#t1-container>table>tbody>tr:nth-child(3)>td.pos {
  background: rgba(199, 123, 48, 0.829);
}

#t2-container>table>tbody>tr:nth-child(1)>td.pos {
  background: rgb(215 198 74);
}

#t2-container>table>tbody>tr:nth-child(2)>td.pos {
  background: rgb(160, 158, 158);
}

#t2-container>table>tbody>tr:nth-child(3)>td.pos {
  background: rgba(199, 123, 48, 0.829);
}



td img {
  width: 90px;
}

.table {
  width: 85%;
}

.out {
  filter: grayscale(80%);
  background: #8b8686 !important;
}

.danger {
  background: #b9672c69;
}

#title-t1 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 600;
  color: #7a7a91;
}

#title-t2 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 600;
  color: #7a7a91;
}

.sumlink {
  color: rgb(42, 83, 22);
  cursor: pointer;
}

.td-twitch {
  min-width: 100px;
  height: 64px;
  text-align: center;
}

.td-twitch:hover {
  filter: hue-rotate(90deg);
}

.td-name {
  min-width: 300px;
  text-align: center;
}

.td-name a:hover {
  color: rgb(83, 95, 204) !important;
}

.td-lp {
  min-width: 100px;
  text-align: center;
}

.td-tier {
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

.td-tier span {
  display: flex;
  min-height: 90px;
  place-items: center;
}

.td-rank {
  min-width: 25px;
  text-align: center;
}

@media (max-width: 1522px) {

  table {
    /* transform: scale(0.8); */
  }

  .ladder-container {
    padding-left: 0px;
  }

  #title-t2 {
    text-align: center;
  }

  #t2-container {
    width: auto;
  }

  #title-t1 {
    text-align: center;
  }

  #t1-container {
    width: auto;
  }
}

@media (max-width: 550px) {

  table {
    transform: scale(0.6);
    display: contents;
  }

  .ladder-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  #title-t2 {
    padding-left: 10px;
    text-align: left;
  }

  #t2-container {
    width: auto;
  }

  #title-t1 {
    padding-left: 10px;
    text-align: left;
  }

  #t1-container {
    width: auto;
  }
}
</style>